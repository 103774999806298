.template-gallery .template-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.template-gallery .close{
    color:#000;
}
.template-gallery .close:hover{
    color:#000;
}


/* .rbt-aux .rbt-close {
    margin-top: 0 !important;
}

.rbt-input{
    border: none !important;
}

.rbt-input:focus{
    border-color: #00000000 !important;
    border-bottom: 3px solid var(--primary) !important;
    outline: 0;
    box-shadow: 0 0 0 00rem #00000000 !important;
} */

.bg-label{
    background: #efefef;
    padding: 0.25rem;
    margin: 0.5rem 0 -0.5rem 0;
}