.home-wrapper {
    padding-left: 70px;
    height: inherit;
}

.home-wrapper-onboarding {
    padding-left: 275px;
    height: inherit;
}

.home-wrapper .sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 70px;
    width: 70px;
    height: 100%;
    margin-left: -70px;
    /* background: #394F5A;*/
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    /* overflow-x: hidden;
    overflow-y: auto; */
}


.home-wrapper .page-content-wrapper {
    position: relative;
    width: 100%;
    height: inherit;
}

.home-wrapper .sidebar-wrapper a {
    padding: 0.25rem;
}

.home-wrapper .sidebar-wrapper .nav-text {
    font-size: 8px;
}

.sidebar-wrapper > ul > li.active, .sidebar-wrapper > ul > li > a:hover, .sidebar-wrapper > ul > li > a:focus {
    background-color: #333333;
}

.home-wrapper .sidebar-wrapper a {
    color: #ffffff;
}

.home-wrapper .sidebar-wrapper .navbar-brand {
    height: 60px;
}

.home-wrapper .sidebar-wrapper .push-top {
    flex-grow: 1;
}

.credits {
    font-size: 9px;
    margin: 0;
}

.credits-text{
    color: #636363;
    font-size: 14px;
}

.credits-sub-text{
    color: #eeeeee;
    font-size: 10px;
}

.product-logo {
    text-align: center;
    padding: 10px;
}

.link {
    display: flex;
    align-items: center;
    line-height: 1;
    padding: 2px;
    text-decoration: none;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.link-sub {
    display: flex;
    align-items: center;
    line-height: 1;
    padding: 4px 10px 10px 10px;
    text-decoration: none;
    -webkit-flex-direction: row;
    flex-direction: row;
    cursor: pointer;
}

.link:hover, .link:focus, .link-sub:hover, .link-sub:focus {
    text-decoration: none;
}

.link .link-text {
    padding: 7px;
    font-size: 9.2px;
}

.link-sub .link-text {
    padding: 7px;
    font-size: 10px;
}

.active-link {
    text-align: center;
    text-decoration: none;

    border-left: 3px solid var(--primary);
}

.sidebar-popover {
    position: absolute !important;
    left: 1rem !important;
    background: rgb(255, 255, 255) !important;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    border-radius:3px;
    min-width: 160px;
}

.sidebar-popover p{
    text-align: center;
    color: #ffffff;
    padding: 5px;
}

.sidebar-popover .popover-content {
    margin: 0;
    padding: 3px 0 3px 0;
}

.sidebar-popover.popover {
    margin-left: -8px;
}

.sidebar-popover.popover.right > .arrow:after {
    border-right-color: #556B76;
}

.side-nav-icon{
    font-size: 0.9rem;
}

.sidebar-user-avatar{
    font-size: 12px;
}