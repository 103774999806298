.onboard-section {
    z-index: 100;
    width: 275px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
    0% {
        width: 0px;
      transform: translateX(-100%);
    }
    100% {
        width: 250px;
      transform: translateX(0);
    }
  }

  