.my-profile {
    padding-top: 10px;
}

.modal-body {
    overflow: auto;
    overflow-wrap: break-word;
    font-size: 15px;
}

#integration {
    text-align: right;
    padding-right: 10px;
}

.avatar {
    padding-bottom: 125px;
}

.index_td {
    width: 100px;
    text-align: center;
}


.icon:hover {
    cursor: pointer;
}

.credits-sub-text {
    color: #636363;
    font-size: 12px;
}

.profile-assets-view {
    height: 350px;
    overflow-y: auto;
}

.profile-assets-table {
    margin: 0 auto;
    border-collapse: collapse;
    border-style: hidden;
}

.profile-assets-table td {
    padding: 1rem;
    border: 1px dashed #dee2e6;
    border-top: 1px dashed #dee2e6 !important;
}