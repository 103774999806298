.glyphicon-envelope, .glyphicon-ok, .glyphicon-remove, #star {
    padding-top: 10px;
}

.glyphicon-log-out {
    padding-top: 2px;
}

.header {
    font-size: 18px;
}

.value {
    font-size: 30px;
}

.headings {
    text-align: left;
}

.text {
    padding-top: 15px;
}

.navigation {
    padding-top: 5px;
}


.text-small {
    font-size: 12px;
}

.people-wrapper {
    display: flex;
    flex-direction: column;
    height: inherit;
}

.people-container {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}
.people-container .people-list-container {
    display: -webkit-flex;
    display: flex;
    flex-grow: 1;
}

.people-container .people-list-container .people-list {
    flex-grow: 1;
}

.people-container .people-list-container .people-list .people-list-content {
    height: 100%;
    margin-left: 10px;
}
.people-container .people-list-container .filter-section {
    width: 240px;
    height: 100%;
}
.people-container .people-list-container .people-list .people-list-content .panel-body{
    height:inherit;
}
.people-container .people-list-container .people-list .people-list-content .panel-body .table-content{
    height:inherit;
}


.segment-section .custom-control-label{
    height: 1.5rem;
}

.dropdown-menu {
    max-height: 300px;
    overflow: auto;
}

#modal {
    text-align: center;
}

a.link-color{
    color: #2c3e50;
}

a.link-color:hover{
    color: #2c3e50;
}

a.link-pointer {
    cursor: pointer;
}

.tagPanel {
    overflow-wrap: break-word;
}

.delete {
    cursor: pointer;
}

.clearAll {
    float: right;
    cursor: pointer;
}

.people-loading {
    background-color: #ecf0f1;
    margin: 5px;
    height: 14px;
}

.people-getting-started-main-icon {
    color: #3498db
}


 .people-list-content .tag-0{
    background-color: #1E88E5;
}
 .people-list-content .tag-1{
    background-color: #3949AB;
}
 .people-list-content .tag-2{
    background-color: #00897B;
}
 .people-list-content .tag-3{
    background-color: #00ACC1;
}
 .people-list-content .tag-4{
    background-color: #FB8C00;
}
 .people-select-all-checkbox>input:indeterminate + label {
    background: lime;
  
}

.people-filter-label{
    padding-top: .3rem!important;
}


.hide-segments{
    width: 0%;
    opacity: 0;
    transition: width 200ms ease-in-out 100ms;
}

.show-segments{
    width: 20%;
    opacity: 1;
    transition: all 200ms ease-in-out 100ms;
}

.hide-filters{
    height: 0;
    /* transform:scaleY(0); */
    transition: transform 300ms ease-in-out;    
}

.show-filters{
    /* transform:scaleY(1); */
    transform-origin: top;
    transition: transform 300ms ease-in-out;
}