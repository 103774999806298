.multi-select-dropdown:focus{
outline: 0;
box-shadow: 0 0 0 0 #00000000;
}
.multi-select-dropdown button{
    background-color: inherit;
    color:#000;
}

.multi-select-dropdown button:active,.multi-select-dropdown button:focus,.multi-select-dropdown button:hover{
    background-color: inherit !important;
    color:#000 !important;
}