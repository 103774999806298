html,
body,
#root,
.App {
  height: 100%;
}

/* .card {
  border: none;
} */

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #f1f3f4;
}

.login-logo img {
  width: 60%;
}

.login-logo {
  margin-bottom: 0;
  margin-top: 15px;
}

.toast-notification {
  z-index: 1051 !important;
}

.toast-notification .success {
  background-color: #4caf50 !important;
}

.navbar-brand:hover {
  cursor: pointer;
}

.nav-pills > li > a {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.text-white {
  color: white;
}

.text-black {
  color: #000;
}

.dropdown-menu > li > a {
  padding: 10px 20px;
}

.dropdown-menu {
  min-width: 100%;
}

/* Breadcumbs */
.breadcrumbs-wrapper {
  padding: 5px;
  border-radius: 4px;
  background-color: #ecf0f1;
  margin: 0 0 8px 0;
}

/* End Breadcumbs */

.cursor-pointer {
  cursor: pointer;
}

.cursor-none{
  cursor: none;
}

/*Temporary theme override*/
.col_fifth,
.col_fourth,
.col_half,
.col_third,
.col_three_fourth,
.col_twothird {
  margin-right: 2%;
}

.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #f2f2f2;
}

.modal-footer {
  padding: 10px;
  background-color: #f2f2f2;
}

.table.no-border tbody tr td {
  border: none;
}

.table td,
.table th {
  padding: 0.5rem;
  vertical-align: middle;
}

.col_fourth:last-child {
  margin-right: 0;
}

.btn-link {
  border: none;
}

.btn-link.active,
.btn-link:active,
.btn-link:hover,
.open > .dropdown-toggle.btn-link {
  background-color: inherit;
  color: var(--success);
  border: none;
}

.btn-link.active.focus,
.btn-link.active:hover,
.btn-link:active.focus,
.btn-link:active:focus,
.btn-link:active:hover,
.open > .dropdown-toggle.btn-link.focus,
.open > .dropdown-toggle.btn-link:focus,
.open > .dropdown-toggle.btn-link:hover {
  background-color: inherit;
  color: var(--success);
  border: none;
}

/*End temporary theme override*/

.action-link {
  cursor: pointer;
}

.intl-tel-input {
  display: block;
}

.vcenter {
  display: flex;
  align-items: center;
}

.UserAvatar {
  display: inline-block;
  color: white;
}

.fa-2x {
  color: inherit;
}

.top-p40 {
  top: 40%;
}

.left-p45 {
  left: 45%;
}

.margin-top-15 {
  margin-top: 15px;
}

.mt-n45{
  margin-top: -2.5rem;
}

.natural-text {
  /*show line breaks, tabs etc in the text (\n \t)*/
  white-space: pre;
}

.word-break-all {
  word-break: break-all;
}

.background-ash {
  background-color: gainsboro;
}

.background-white {
  background-color: #ffffff;
}

.padding-30 {
  padding: 30px;
}

.btn-transparent {
  background-color: transparent;
  color: #339bce;
  border: none;
}

.btn-transparent:hover,
.btn-transparent:active,
.btn-transparent:focus {
  background-color: transparent !important;
  color: #339bce !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000000;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-weight: 600;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #00000000;
  transition: all 150ms;
}

.badge {
  border-radius: 0;
}

p {
  margin-bottom: 0rem;
}

/* Add to theme */

.form-group.custom {
  margin-bottom: -0.5rem !important;
}

.top-right {
  top: 0rem;
  right: 0rem;
}

.top-left {
  top: 0rem;
  left: 0rem;
}

.top-left-1 {
  top: 1rem;
  left: 1rem;
}

.card-columns {
  column-count: 4;
}

.rounded-circle-left {
  border-radius: 1.25rem 0 0 1.25rem;
}

.search-icon {
  width: 36px;
  padding: 0;
  margin-left: -1rem;
}

.form-control{
  font-size: 0.875rem;
  padding-left: 0.25rem;
  padding-left: 0.25rem;
}

.bring-to-top {
  z-index: 1000;
}

.people-page.UserAvatar {
  font-size: 1.35rem;
  color: white;
}

.ReactVirtualized__Table__Grid,
.ReactVirtualized__List__Grid {
  outline: none;
}
.ReactVirtualized__Table svg {
  vertical-align: baseline;
}

.custom-checkbox .custom-control-label::before {
  margin: 0.15rem 0 0 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after,
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  margin: 0.15rem 0 0 0;
}

.custom-control-label::before {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 0px #00000000;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0px 0px 0px #00000000;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.custom-switch .custom-control-label::after {
  background-color: var(--dark);
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--success);
  background-color: var(--success);
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #83ca94;
  border-color: #83ca94;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--muted);
}

.custom-control-label {
  height: 1.9rem;
}

.line-height-1 {
  line-height: 0.9;
}

.fa-fh {
  height: 1.25em;
}

.form-control:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.input-group {
  padding-top: 0;
}

.height-inherit {
  height: inherit;
}

.dashed {
  border-top: 1px dashed #bfbfbf;
}

.apply-filter-button {
  border-radius: 1rem;
}

.shadow {
  box-shadow: 0 0.5rem 1rem #e6e6e6 !important;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.extra-small {
  font-size: 70%;
}


.rbt-input{
  border-radius: 0;
  font-size: 0.75rem;
}

.dropdown-menu{
  font-size: .75rem;
}

.btn {
  border-radius: 0;
}

.disabled{
  opacity: .65;
  filter: grayscale(1);
  pointer-events: none;
}