.top-nav-bar {
    z-index: 1;
    border-radius: 0 !important;
}

.top-nav-bar .nav-components {
    margin-top: 10px;
    padding: 5px;
}

.top-nav-bar .module-link {
    color: #ffffff;
}

.top-nav-bar .module-link:hover {
    color: inherit;
}

.top-nav-bar .module-link.active-link {
    background-color: #6AB3E3;
}

a.nav-link.active {
    color: #000000;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-weight: 600;
}